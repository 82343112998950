import axiosInstance from '../../axios';
import { setFilteredProducts, setOneProd, setProd } from '../slices/product-slice';

export const getNewProducts = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("prod/get?page=1&limit=8?status=1&isVisible=1");
    if (res.data.code === 1) {
      dispatch(setProd(res.data.data));
      dispatch(setFilteredProducts(res.data.data))
    } else {
      // Handle case where the code is not 1 if needed
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch banners:', err);
  }
};


export const getProduct = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`prod/get?id=${id}&status=1&isVisible=1`);
    console.log("getProduct id :", id);
    
    if (res.data.code === 1) {
      dispatch(setOneProd(res.data.data));
    } else {
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch product:', err);
  }
};


export const getNewFilterdProducts = ({
  page,
  limit,
  brandId = '',
  category = '',
  disPer = '',
  color = ''
}) => async (dispatch) => {
  try {
    // Construct URL with dynamic query parameters
    const url = `prod/get?page=${page}&limit=${limit}&status=1&isVisible=1` +
                `${brandId ? `&brandId=${brandId}` : ''}` +
                `${category ? `&category=${category}` : ''}` +
                `${disPer ? `&disPer=${disPer}` : ''}` +
                `${color ? `&color=${color}` : ''}`;

    const res = await axiosInstance.get(url);

    if (res.data.code === 1) {
      dispatch(setProd(res.data.data));
      dispatch(setFilteredProducts(res.data.data));
    } else {
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch products:', err);
  }
};
