import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setActiveSort } from '../../helpers/product';

const ShopColor = ({ colors, getSortParams }) => {
  const [selectedColor, setSelectedColor] = useState('');

  const handleColorClick = (color) => {
    setSelectedColor(color);
    getSortParams('color', color);
    setActiveSort({ target: { value: color } }); // Assuming setActiveSort expects an event-like object
  };

  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">Color</h4>
      <div className="sidebar-widget-list mt-20">
        {colors ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  className={selectedColor === '' ? 'active' : ''}
                  onClick={() => handleColorClick('')}
                >
                  <span className="checkmark" /> All Colors
                </button>
              </div>
            </li>
            {colors.map((color, key) => (
              <li key={key}>
                <div className="sidebar-widget-list-left">
                  <button
                    className={selectedColor === color ? 'active' : ''}
                    onClick={() => handleColorClick(color)}
                  >
                    <span className="checkmark" /> {color}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          'No colors found'
        )}
      </div>
    </div>
  );
};

ShopColor.propTypes = {
  colors: PropTypes.array.isRequired,
  getSortParams: PropTypes.func.isRequired
};

export default ShopColor;

  

// import PropTypes from "prop-types";

// import { setActiveSort } from "../../helpers/product";

// const ShopColor = ({ colors, getSortParams }) => {
//   return (
//     <div className="sidebar-widget mt-50">
//       <h4 className="pro-sidebar-title">Color </h4>
//       <div className="sidebar-widget-list mt-20">
//         {colors ? (
//           <ul>
//             <li>
//               <div className="sidebar-widget-list-left">
//                 <button
//                   onClick={e => {
//                     getSortParams("color", "");
//                     setActiveSort(e);
//                   }}
//                 >
//                   <span className="checkmark" /> All Colors{" "}
//                 </button>
//               </div>
//             </li>
//             {colors.map((color, key) => {
//               return (
//                 <li key={key}>
//                   <div className="sidebar-widget-list-left">
//                     <button
//                       onClick={e => {
//                         getSortParams("color", color);
//                         setActiveSort(e);
//                       }}
//                     >
//                       <span className="checkmark" /> {color}{" "}
//                     </button>
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//         ) : (
//           "No colors found"
//         )}
//       </div>
//     </div>
//   );
// };

// ShopColor.propTypes = {
//   colors: PropTypes.array,
//   getSortParams: PropTypes.func
// };

// export default ShopColor;
