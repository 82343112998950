import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axios';

export const loginUser = createAsyncThunk(
  'user/login',
  async (userData, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post('user/login', userData);
      if (res.data.code === 1) {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          })
        );
        return {
          user: res.data.user,
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
      } else {
        return rejectWithValue('Login failed');
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  'user/signup',
  async (userData, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post('user/signup', userData);
      // return response.data;
      if (res.data.code === 1) {
        // localStorage.setItem(
        //   'auth',
        //   JSON.stringify({
        //     access_token: res.data.access_token,
        //     refresh_token: res.data.refresh_token,
        //   })
        // );
        return {
          user: res.data.user,
          successMessage: res.data.message
        };
      } else {
        return rejectWithValue('Login failed');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Registration failed');
    }
  }
);

// export const registerUser = (userData) => async (dispatch) => {
//   try {
//     const res = await axiosInstance.post("user/register", userData);
    // if (res.data.code === 1) {
    //   dispatch(setUser(res.data.user));
    //   // You might want to handle successful registration differently
    //   // For example, automatically logging in the user or showing a success message
    // } else {
    //   throw new Error(res.data.message || 'Registration failed');
    // }
//   } catch (err) {
//     console.error('Failed to register:', err);
//     throw err;
//   }
// };

// export const registerUser = createAsyncThunk(
//   'user/signup',
//   async (userData, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('user/signup', userData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

