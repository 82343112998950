import PropTypes from "prop-types";
import { setActiveSort } from "../../helpers/product";

const ShopBrand = ({ tags, getSortParams, selectedBrand }) => {
  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">Brand</h4>
      <div className="sidebar-widget-tag mt-25">
        {tags ? (
          <ul>
            <li>
              <button
                className={`text-uppercase ${!selectedBrand ? 'active' : ''}`}
                onClick={e => {
                  getSortParams("brand", "");
                  setActiveSort(e);
                }}
              >
                All Brands
              </button>
            </li>
            {tags.map((tag, key) => (
              <li key={key}>
                <button
                  className={`text-uppercase ${selectedBrand === tag ? 'active' : ''}`}
                  onClick={e => {
                    getSortParams("brand", tag);
                    setActiveSort(e);
                  }}
                >
                  {tag}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          "No brands found"
        )}
      </div>
    </div>
  );
};

ShopBrand.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  selectedBrand: PropTypes.string
};

export default ShopBrand;



// import PropTypes from "prop-types";

// import { setActiveSort } from "../../helpers/product";

// const ShopTag = ({ tags, getSortParams }) => {
//   return (
//     <div className="sidebar-widget mt-50">
//       <h4 className="pro-sidebar-title">Brand </h4>
//       <div className="sidebar-widget-tag mt-25">
//         {tags ? (
//           <ul>
//             {tags.map((tag, key) => {
//               return (
//                 <li key={key}>
//                   <button
//                     onClick={e => {
//                       getSortParams("tag", tag);
//                       setActiveSort(e);
//                     }}
//                   >
//                     {tag}
//                   </button>
//                 </li>
//               );
//             })}
//           </ul>
//         ) : (
//           "No brands found"
//         )}
//       </div>
//     </div>
//   );
// };

// ShopTag.propTypes = {
//   getSortParams: PropTypes.func,
//   tags: PropTypes.array
// };

// export default ShopTag;
