import React from 'react';
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from 'react-redux';
import ShopSearch from "../../components/product/ShopSearch";
import ShopColor from "../../components/product/ShopColor";
import ShopSize from "../../components/product/ShopSize";
import ShopTag from "../../components/product/ShopTag";
import { setFilteredProducts, clearFilters } from '../../store/slices/product-slice';
import MyShopCategory from '../../components/product/MyShopCategory';

const MyShopSidebar = ({ sideSpaceClass }) => {
  const dispatch = useDispatch();
  const { prod, productFilters } = useSelector(state => state.product);

  const uniqueColors = getMyIndividualColors(prod);
  const uniqueCategory = getMyIndividualCategory(prod);
  const uniqueBrand = getMyIndividualBrand(prod);
  const uniqueDiscount = getMyIndividualDiscount(prod);

  const getSortParams = (type, value) => {
    dispatch(setFilteredProducts({ type, value }));
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  return (
    <div className={clsx("sidebar-style", sideSpaceClass)}>
      {/* <ShopSearch /> */}
      <button onClick={handleClearFilters}>Clear All Filters</button>
      <ShopColor colors={uniqueColors} getSortParams={getSortParams} selectedColor={productFilters.color} />
      <MyShopCategory cat={uniqueCategory} getSortParams={getSortParams} selectedSize={productFilters.category} />
      <ShopTag tags={uniqueBrand} getSortParams={getSortParams} selectedTag={productFilters.brand} />
      {/* <ShopSize disc={uniqueDiscount} getSortParams={getSortParams} selectedSize={productFilters.discount} /> */}
      CATEGORY,
PRICE RANGE,
BRAND,
MODEL,
SERIES,
COLOR,
RAM,
STORAGE,
NETWORK,
WARRENTY [YES/NO],
USED TIME [1 MONTH, 3 MNONTH, 6 MONTH, 1 YEAR, 2 YEAR, 5 YEAR, MORE THAN 5 YEARS]
    </div>
  );
};


MyShopSidebar.propTypes = {
  sideSpaceClass: PropTypes.string
};

export default MyShopSidebar;


export const getMyIndividualColors = products => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map(product => product.color)));
};

export const getMyIndividualCategory = products => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map(product => product.category)));
};

// export const getMyIndividualCategory = products => {
//   // Ensure products is an array and use map to extract category
//   if (!Array.isArray(products)) return [];

//   // Extract category directly from each product
//   const productCategory = products.map(product => product.category);

//   // Remove duplicates if needed
//   const individualProductCategory = Array.from(new Set(productCategory));

//   return individualProductCategory;
// };

export const getMyIndividualBrand = products => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map(product => product.brandName)));
};

export const getMyIndividualDiscount = products => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map(product => product.disPer)));
};




// import PropTypes from "prop-types";
// import clsx from "clsx";
// import {
//   getIndividualCategories,
//   getIndividualTags,
//   getIndividualColors,
//   getProductsIndividualSizes
// } from "../../helpers/product";
// import ShopSearch from "../../components/product/ShopSearch";
// import ShopCategories from "../../components/product/ShopCategories";
// import ShopColor from "../../components/product/ShopColor";
// import ShopSize from "../../components/product/ShopSize";
// import ShopTag from "../../components/product/ShopTag";

// const MyShopSidebar = ({ products, getSortParams, sideSpaceClass }) => {
  
//   // const uniqueCategories = getIndividualCategories(products);
//   // const uniqueColors = getIndividualColors(products);
//   const uniqueColors = getMyIndividualColors(products);
//   const uniqueCategory = getMyIndividualCategory(products);
//   const uniqueBrand = getMyIndividualBrand(products);
//   const uniqueTags = getMyIndividualDiscount(products);  

//   console.log("uniqueColors :", uniqueColors);
  

//   return (
//     <div className={clsx("sidebar-style", sideSpaceClass)}>
//       {/* shop search */}
//       <ShopSearch />

//       {/* filter by categories */}
//       {/* <ShopCategories
//         categories={uniqueCategories}
//         getSortParams={getSortParams}
//       /> */}

//       {/* filter by color */}
//       <ShopColor colors={uniqueColors} getSortParams={getSortParams} />

//       {/* filter by size */}
//       <ShopSize sizes={uniqueCategory} getSortParams={getSortParams} />
      
//       {/* filter by tag */}
//       <ShopTag tags={uniqueBrand} getSortParams={getSortParams} />

//       <ShopSize sizes={uniqueTags} getSortParams={getSortParams} />
//     </div>
//   );
// };

// MyShopSidebar.propTypes = {
//   getSortParams: PropTypes.func,
//   products: PropTypes.array,
//   sideSpaceClass: PropTypes.string
// };

// export default MyShopSidebar;



// export const getMyIndividualColors = products => {
//   // Ensure products is an array and use map to extract colors
//   if (!Array.isArray(products)) return [];

//   // Extract colors directly from each product
//   const productColors = products.map(product => product.color);

//   // Remove duplicates if needed
//   const individualProductColors = Array.from(new Set(productColors));

//   return individualProductColors;
// };

// export const getMyIndividualCategory = products => {
//   // Ensure products is an array and use map to extract category
//   if (!Array.isArray(products)) return [];

//   // Extract category directly from each product
//   const productCategory = products.map(product => product.category);

//   // Remove duplicates if needed
//   const individualProductCategory = Array.from(new Set(productCategory));

//   return individualProductCategory;
// };

// export const getMyIndividualBrand = products => {
//   // Ensure products is an array and use map to extract brand
//   if (!Array.isArray(products)) return [];

//   // Extract brand directly from each product
//   const productBrand = products.map(product => product.brandName);

//   // Remove duplicates if needed
//   const individualProductBrand = Array.from(new Set(productBrand));

//   return individualProductBrand;
// };

// export const getMyIndividualDiscount = products => {
//   // Ensure products is an array and use map to extract brand
//   if (!Array.isArray(products)) return [];

//   // Extract Discount directly from each product
//   const productDiscount = products.map(product => product.disPer);

//   // Remove duplicates if needed
//   const individualProductDiscount = Array.from(new Set(productDiscount));

//   return individualProductDiscount;
// };
