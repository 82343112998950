import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
    name: "product",
    initialState: {
      products: [],
      prod: [],
      filteredProducts: [],
      productFilters: {
        color: "",
        category: "",
        brand: "",
        discount: "",
        sort: ""
      },
      oneprod: null,
    },
    reducers: {
      setProducts(state, action) {
        state.products = action.payload;
      },
      setProd(state, action) {
        state.prod = action.payload;
        state.filteredProducts = action.payload;
      },
      setOneProd(state, action) {
        state.oneprod = action.payload;
      },
      setFilteredProducts(state, action) {
        const { type, value } = action.payload;
        state.productFilters[type] = value;
        state.filteredProducts = filterProducts(state.prod, state.productFilters);
      },
      clearFilters(state) {
        state.productFilters = {
          color: "",
          category: "",
          brand: "",
          discount: "",
          sort: ""
        };
        state.filteredProducts = state.prod;
      },
    },
  });

  const filterProducts = (products, filters) => {
    let filteredProducts = products.filter(product => {
      return (
        (!filters.color || product.color === filters.color) &&
        (!filters.category || product.category === filters.category) &&
        (!filters.brand || product.brandName === filters.brand) &&
        (!filters.discount || product.disPer === filters.discount)
      );
    });
  
    // Sort products based on the filter
    if (filters.sort === "priceHighToLow") {
      filteredProducts = filteredProducts.sort((a, b) => b.price - a.price);
    } else if (filters.sort === "priceLowToHigh") {
      filteredProducts = filteredProducts.sort((a, b) => a.price - b.price);
    }
  
    return filteredProducts;
  };
  
//   const filterProducts = (products, filters) => {
//     return products.filter(product => {
//       return (
//         (!filters.color || product.color === filters.color) &&
//         (!filters.category || product.category === filters.category) &&
//         (!filters.brand || product.brandName === filters.brand) &&
//         (!filters.discount || product.disPer === filters.discount)
//       );
//     });
//   };
  
  export const { setProducts, setProd, setOneProd, setFilteredProducts, clearFilters } = productSlice.actions;
  export default productSlice.reducer;
  
    



// const { createSlice } = require("@reduxjs/toolkit");

// const productSlice = createSlice({
//   name: "product",
//   initialState: {
//     products: [],
//     prod: [],
//     filteredProducts: [],
//     productFilters: {
//       color: "",
//       category: "",
//       brand: "",
//       discount: "",
//     },
//     oneprod: null,
//   },
//   reducers: {
//     setProducts(state, action) {
//       state.products = action.payload;
//     //   state.filteredProducts = action.payload;
//     },
//     setProd(state, action) {
//       state.prod = action.payload;
//       state.filteredProducts = action.payload;
//     },
//     setOneProd(state, action) {
//       state.oneprod = action.payload;
//     },
//     setFilteredProducts(state, action) {
//         const { type, value } = action.payload;
//         state.productFilters[type] = value;
//         state.filteredProducts = filterProducts(state.prod, state.productFilters);
//       },
//       clearFilters(state) {
//         state.productFilters = {
//           color: "",
//           category: "",
//           brand: "",
//           discount: "",
//         };
//         state.filteredProducts = state.products;
//       },
//   },
// });



// const filterProducts = (prod, filters) => {
//     return prod.filter(product => {
//         return (
//             (!filters.color || product.color === filters.color) &&
//             (!filters.category || product.category === filters.category) &&
//             (!filters.brand || product.brandName === filters.brand) &&
//             (!filters.discount || product.disPer === filters.discount)
//         );
//     });
// };

// export const { setProducts, setProd, setOneProd, setFilteredProducts, clearFilters } = productSlice.actions;
// export default productSlice.reducer;
// // export const { setProducts, setProd, setOneProd } = productSlice.actions;
// // export default productSlice.reducer;
