const { createSlice } = require('@reduxjs/toolkit');

const citystatecountrySlice = createSlice({
    name: 'citystatecountry',
    initialState: {
        city: [],
        state: [],
        country: [],
    },
    reducers: {
        setCity(state, action) {
            state.city = action.payload;
        },
        setState(state, action) {
            state.state = action.payload;
        },
        setCountry(state, action) {
            state.country = action.payload;
        }
    },
});

export const { setCity, setState, setCountry } = citystatecountrySlice.actions;
export default citystatecountrySlice.reducer;