module.exports = {
    google: {
      API_KEY: "",
      CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
      SECRET: "",
    },
    facebook: {
      APP_ID: "",
    },
    // API_URL:"http://192.168.29.230:3030/api/"
    // API_URL:"http://13.126.28.117:3030/api/"
    API_URL:"https://api.09mobilegallery.com/api/"
  }